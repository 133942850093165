import React from 'react';
import Navbar from '../Components/Navbar/navbar';
import SelectMes from '../Components/Common/selectmes';
import Footer from '../Components/Footer/footer';

function Caudais(){

    return (
      <>
      <Navbar/>  
      <div style={{paddingTop: '95px'}}>
      <SelectMes/>
      </div>
      <Footer/>
      </>
    )
  }

  export default Caudais;