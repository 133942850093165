import React, { useState, useEffect } from 'react';
import { getToken } from './common';
import axios from 'axios';
import './showcaudais.css';

function ShowCaudais({mes}){
  const [caudais, setCaudais] = useState([]);

  useEffect(function(){
    let isMounted = true; 
    let m = mes; 
    const params = new URLSearchParams({
      mes: m,
      token: getToken()
    }).toString();
  
    var url =
            "https://gateway.inforserv.pt/cgirdw/restserver/client/caudais?" +
            params;
      
    if (isMounted) {     
    axios.get(url).then(response => {
      setCaudais(response.data);
    }).catch(console.log);
   }
   return () => { isMounted = false };
}, [mes]); 

   const listItems = caudais.map((caudal) =>
   <div key={caudal.id.toString()} className="accordion-item">
   <h2 className="accordion-header" id={"heading" + caudal.id.toString()}>
   <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + caudal.id.toString()} aria-expanded="false" aria-controls={"collapse" + caudal.id}>
   Data/Hora: {caudal.data_hora}
   </button>
   </h2>
   <div id={"collapse" + caudal.id.toString()} className="accordion-collapse collapse" aria-labelledby={"heading" + caudal.id.toString()} data-bs-parent="#accordionCaudais">
   <div className="accordion-body">
   <div className="card">
   <div className="card-body">
   <h6 className="card-subtitle mb-2 text-muted">Lote 18</h6>
   <p className="card-text">Diário: {caudal.lote18acumulado1} Mensal: {caudal.lote18acumulado2}</p>
   <h6 className="card-subtitle mb-2 text-muted">Lote 37</h6>
   <p className="card-text">Diário: {caudal.lote37acumulado1} Mensal: {caudal.lote37acumulado2}</p>
   <h6 className="card-subtitle mb-2 text-muted">Lote 38</h6>
   <p className="card-text">Diário: {caudal.lote38acumulado1} Mensal: {caudal.lote38acumulado2}</p>
   <h6 className="card-subtitle mb-2 text-muted">Lote 39</h6>
   <p className="card-text">Diário: {caudal.lote39acumulado1} Mensal: {caudal.lote39acumulado2}</p>
   <h6 className="card-subtitle mb-2 text-muted">Lago 6</h6>
   <p className="card-text">Diário: {caudal.lago6acumulado1} Mensal: {caudal.lago6acumulado2}</p>
   <h6 className="card-subtitle mb-2 text-muted">Lago 21</h6>
   <p className="card-text">Diário: {caudal.lago21acumulado1} Mensal: {caudal.lago21acumulado2}</p>
     </div>
   </div>
     </div>
    </div>
 </div>);
    
    return (
      <div className="accordion" id="accordionCaudais">
      {listItems}
      </div>
    )
  }

  export default ShowCaudais;