import React, { useState, useEffect }  from 'react';
import axios from 'axios';
import { BrowserRouter, Route } from 'react-router-dom';
import PrivateRoute from './Components/Common/privateroute';
import PublicRoute from './Components/Common/publicroute';
import ReactLogo from "./assets/img/technologies/qdo-logo-transparent.svg";
import './App.css';
import Login from './Login/login';
import AlterarPassword from './Components/alterarpassword';
import RecuperarPassword from './Components/recuperarpassword';
import Home from './Home/home';
import Caudais from './Caudais/caudais';
import { getToken, getUser, removeUserSession, setUserSession } from './Components/Common/common';
import { useCookies } from 'react-cookie';
//import jwt from 'jsonwebtoken';
import ScrollToTop from "./Components/ScrollToTop";
// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

function App() {
  const [authLoading, setAuthLoading] = useState(true);
  const [cookies] = useCookies(['user']);
  
  useEffect(() => {
    let isMounted = true;

    if (!cookies.userid) {
    const token = getToken();
    if (!token) {
      isMounted = false;
      return;
    } 

    const user = getUser();
    if (!user) {
      isMounted = false;
      return;
    }

    const params = new URLSearchParams({
      email: user.email,
      token: token
    }).toString();
  
    const url =
            "https://gateway.inforserv.pt/cgirdw/restserver/client/verifyToken?" +
            params;

    axios.get(url).then(response => {
      if (response.data.error === true) {
        if (isMounted) {
        removeUserSession();
        setAuthLoading(false);
        }
      } else {
        if (isMounted) {
      var u = JSON.stringify(response.data.user);
      u = JSON.parse(u);    
      setUserSession(u.userId, true, response.data.token, response.data.user);
      setAuthLoading(false);
        }
      }
    }).catch(error => {
        if (isMounted) {
      removeUserSession();
      setAuthLoading(false);
        }
    })
  }   
    return () => { isMounted = false };
  }, [cookies]);

  if (authLoading && getToken()) {
    return (
      <div className="token-wrapper"> 
      <div className="token">
      <img className="App-logo" src={ReactLogo} alt="Logo" />
      </div>
      </div>
    )
  }

  return ( 
      <BrowserRouter>
      <ScrollToTop />
      <Route exact path='/' component={Login} />
      <PublicRoute exact path='/altpassword' component={AlterarPassword} />
      <PublicRoute exact path='/recpassword' component={RecuperarPassword} />      
      <PrivateRoute path='/home' component={Home} /> 
      <PrivateRoute path='/caudais' component={Caudais} />  
      </BrowserRouter>
  );
}

export default App;
