import React from 'react';
import Navbar from '../Components/Navbar/navbar';
import Dashboard from '../Dashboard/dashboard';
import Footer from '../Components/Footer/footer';
import './home.css';

function Home(){
    return <div>
      <Navbar/>
      <Dashboard/>
      <Footer/>
    </div>
  }

export default Home;