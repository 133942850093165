import React, { useState } from "react";
import axios from 'axios';
import { setRecPassData, getRecPassEmail, getRecPassToken } from '../Components/Common/common';
import * as qs from 'query-string';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import './alterarpassword.css';

import BgImage from "../assets/img/login.svg";
import { useEffect } from "react";

function AlterarPassword(props){
  const parsed = qs.parse(props.location.search); 
  const [recemail, setRecemail] = useState('');
  const [rectoken, setRectoken] = useState('');
  useEffect(() => {
    let isMounted = true;
  if (isMounted) {
    if (parsed.email && parsed.token) {
        console.log('parsed.token: ' +  parsed.token);
     setRecPassData(parsed.email, parsed.token);
     setRecemail(parsed.email);
     setRectoken(parsed.token);
     } else {
     setRecemail(getRecPassEmail());
     setRectoken(getRecPassToken());
     console.log('session.token: ' +  rectoken)
     } 
  }
  return () => { isMounted = false };
  }, [parsed, rectoken]);
  const [loading, setLoading] = useState(false);
  const [sucess, setSucess] = useState(false);
  const email = useFormInput(recemail);
  const password = useFormInput('');
  const confpassword = useFormInput('');
  const [error, setError] = useState(null);
  var data = {};
  var params = new URLSearchParams({
    token: rectoken,   
    email: email.value,
    password: password.value,
  }).toString();

  const url =
          "https://gateway.inforserv.pt/cgirdw/qdocloud/client/setpassword?" +
          params;

  const _handleKeyDown = function(e) {
            if (e.key === 'Enter') {
              handleAlterarPassword();
            }
  } 
  
  const _handleSucess = function() {
    props.history.push('/');
  }

  const handleAlterarPassword = (e) => {
        e.preventDefault();
        let valid = false;
        if (email.value === '') {
            setError('Introduza o Endereço de Email!')
            return;
        }
        if (password.value === '') {
            setError('Introduza a Palavra Passe!')
            return;
        }
        if (confpassword.value === '') {
            setError('Confirme a Palavra Passe!')
            return;
        }
        if (password.value !== confpassword.value) {
            setError('A Palavra Pase não coincide!')
            return;
        }

        valid = true;

    if (valid) {
       setError(null);
       setLoading(true);
       axios.post(url, data).then(response => {
       setLoading(false);
       if (response.data.error === true) {
       setError(response.data.message);
      } else {
       if (response.data.sucess === true) {
           setSucess(true)
       }
      }
    }).catch(error => {
      setLoading(false);
      console.log('handleAlterar: ' + error);
      setError("Algo deu errado. Por favor, tente novamente mais tarde.");
    });
   }
  }

    return (
        <>
        <main>
          <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
            <Container>
              <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                    <div className="text-center text-md-center mb-4 mt-md-0">
                      <h3 className="mb-0">Definir a Palavra Passe</h3>
                    </div>
                    <Form className="mt-4">
                      <Form.Group id="email" className="mb-4">
                        <Form.Label>Endereço de Email</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                          <Form.Control autoFocus required type="email" {...email} placeholder="Endereço de Email" />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group>
                        <Form.Group id="password" className="mb-4">
                          <Form.Label>Palavra Passe</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>
                            <Form.Control required type="password" {...password} placeholder="Palavra Passe" />
                          </InputGroup>
                        </Form.Group>
                      </Form.Group> 
                      <Form.Group>
                        <Form.Group id="confpassword" className="mb-4">
                          <Form.Label>Confirmar Palavra Passe</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>
                            <Form.Control required type="password" {...confpassword} placeholder="Confirmar Palavra Passe" onKeyDown={_handleKeyDown} />
                          </InputGroup>
                        </Form.Group>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <Card.Link href="/" className="small text-end" style={{ marginLeft: "auto" }}>Iniciar Sessão</Card.Link>
                        </div>
                      </Form.Group> 
                      <Button variant="primary" type="submit" onClick={handleAlterarPassword} className="w-100" disabled={loading}>{loading ? 'Aguarde...' : 'Definir a Palavra Passe'}</Button>
                    </Form>

                    {error && <div className="alert alert-danger mt-2" role="alert">{error}</div>}
  
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        {
          sucess ? <SweetAlert
                   success
                   title="Difinir a Palavra Passe"
                   onConfirm={_handleSucess}
                   >
                   Palavra Passe definida com sucesso!<br/>
                   Pode agora Iniciar Sessão.
                   </SweetAlert> : null
        }
        </>
      )
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value);
   }
    return {
      value,
      onChange: handleChange
    }
  }

export default AlterarPassword;