import React from 'react';
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import './footer.css';

import InforServImage from "../../assets/img/brand/inforserv-partner.svg";

function Footer(){
  var ano = new Date().getFullYear(); 

    return <div>
  <footer className="footer section py-5">
  <Row>
    <Col xs={12} lg={6} className="mb-4 mb-lg-0">
      <p className="mb-0 text-center text-xl-left">
      Desenvolvido por&nbsp; 
      <Card.Link href="https://www.inforserv.pt" target="_blank" className="text-blue text-decoration-none fw-normal">
         <img alt="" src={`${InforServImage}`} />
      </Card.Link>
      &copy; {ano}
      </p>
    </Col>
    <Col xs={12} lg={6}>
    </Col>
  </Row>
</footer>
  </div>;

  }

export default Footer;