import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { getUser, removeUserSession } from '../Common/common';
import { useHistory, Link } from 'react-router-dom';
import './navbar.css';
import LogoImage from "../../assets/img/brand/logo-white-navbar.svg";

function Navbar(){
  const history = useHistory();
  const user = getUser();
  const [currentpath, setCurrentPath] = useState(history.location.pathname);
  const [isHomeDisabled, setHomeDisabled] = useState(false);
  const [isCaudaisDisabled, setCaudaisDisabled] = useState(false);
  const [cookie] = useState(!user ? true : false);
  const [cookies] = useCookies(['user']);
  const [nome, setNome] = useState('');

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (cookie) {
        setNome(cookies.name)
      } else {
        setNome(user.name)
      }  
     if (history.location.pathname === '/home') {
        setHomeDisabled(true);
    } else {
        setHomeDisabled(false);
    }
     if (history.location.pathname === '/caudais') {
        setCaudaisDisabled(true);
    } else {
      setCaudaisDisabled(false);
    }
}
  return () => { isMounted = false };
}, [history, cookie, cookies, user]);

  // handle click event of logout button
  const handleLogout = () => {
        removeUserSession();
        history.push('/'); 
  }

  // handle menu click event
  const handleClick = () => {
        setCurrentPath(history.location.pathname);
        if (currentpath === '/home') {
          setHomeDisabled(true);
        } else {
          setHomeDisabled(false);
        }
        if (currentpath === '/caudais') {
          setCaudaisDisabled(true);
        } else {
          setCaudaisDisabled(false);
        }
}

    return <nav className="navbar fixed-top navbar-expand-lg navbar-dark">

    <div className="container-fluid">
            
        <a className="navbar-brand" title="QdO Cloud" href="/#">
          <img src={`${LogoImage}`} alt="" width="185" height="48" />
        </a>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
            {isHomeDisabled ?
              <Link to= "/home" replace onClick={handleClick} className="nav-link" aria-current="page" >Painel</Link>
              :
              <Link to= "/home" onClick={handleClick} className="nav-link" aria-current="page" >Painel</Link>
            }
            </li>
            <li className="nav-item">
            {isCaudaisDisabled ?
            <Link to="/caudais" replace onClick={handleClick} className="nav-link" aria-current="page" >Caudais</Link>
            :
            <Link to="/caudais" onClick={handleClick} className="nav-link" aria-current="page" >Caudais</Link>
              }
            </li>
          </ul>
          <span className="navbar-text">
          {nome}&nbsp;&nbsp;
          </span>
          <span className="navbar-text">
          <button type="button" onClick={handleLogout} className="btn btn-light">Terminar Sessão</button>
          </span>
        </div>    
    </div>
  </nav>;
  }

export default Navbar;