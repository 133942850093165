import React, { useState } from 'react';
import Select from 'react-select';
import ShowCaudais from './showcaudais';

function SelectMes() {
  //const [loading, setLoading] = useState(true);
  const currentMonth = (new Date().getMonth() + 1);
  const [selectedValue, setSelectedValue] = useState(currentMonth);

  const options = [
    { value: 1, label: 'Janeiro' },
    { value: 2, label: 'Fevereiro' },
    { value: 3, label: 'Março' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Maio' },
    { value: 6, label: 'Junho' },
    { value: 7, label: 'Julho' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Setembro' },
    { value: 10, label: 'Outubro' },
    { value: 11, label: 'Novembro' },
    { value: 12, label: 'Dezembro' }
  ];

  const dot = (color = '#fd7b33') => ({
    alignItems: 'center',
    display: 'flex',
  
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white', borderColor: null }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? '#f7f4f3'
          : isFocused
          ? '#f7f4f3'
          : null,
        color: isDisabled
          ? '#ccc'
          : '#fd7b33',
        cursor: isDisabled ? 'not-allowed' : 'default',
        padding: 5,
        ':active': {
          ...styles[':active'],
          backgroundColor:
            !isDisabled && (isSelected ? '#f7f4f3' : '#ffffff'),
        },
      };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles) => ({ ...styles, ...dot('#fd7b33') }),
  };
   
  const handleChange = e => {
    setSelectedValue(e.value);
  };
 
    return (
      <div>
      <div style={{width: '50%', marginLeft: 'auto', marginRight: 'auto', padding: '10px'}}> 
      <Select
        styles={colourStyles}
        value={options.find(obj => obj.value === selectedValue)}
        onChange={handleChange}
        options={options}
        theme={theme => ({
          ...theme,
          borderRadius: 10,
          colors: {
            ...theme.colors,
            primary25: '#f1f1f1',
            primary: '#f1f1f1',
            neutral90: '#fd7b33',
          },
        })}
        placeholder="Selecione o Mês"
      />
      </div>
      <ShowCaudais mes={selectedValue} />
      </div>
    );
}

export default SelectMes;