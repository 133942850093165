// return the user data from the session storage
export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }

  export const getUserID = () => {
    return sessionStorage.getItem('userid') || null;
  }

  export const setRecPassData = (email, rectoken) => {
    sessionStorage.setItem('email', email);
    sessionStorage.setItem('rectoken', rectoken);
  }

  export const getRecPassEmail = () => {
    return sessionStorage.getItem('email') || null;
  }

  export const getRecPassToken = () => {
    return sessionStorage.getItem('rectoken') || null;
  }
  
  // return the token from the session storage
  export const getToken = () => {
    return sessionStorage.getItem('token') || null;
  }
  
  // return the token from the session storage
  export const isAuthenticated = () => {
    return sessionStorage.getItem('auth') || null;
  }
  
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('auth');
  }
  
  // set the token and user from the session storage
  export const setUserSession = (id, auth, token, user) => {
    sessionStorage.setItem('userid', id);
    sessionStorage.setItem('auth', auth);
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
  }