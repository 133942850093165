import React, { useState } from "react";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import './recuperarpassword.css';

import BgImage from "../assets/img/login.svg";

function RecuperarPassword(props){
  const [loading, setLoading] = useState(false);
  const [sucess, setSucess] = useState(false);
  const email = useFormInput('');
  const [error, setError] = useState(null);
  var data = {};
  var params = new URLSearchParams({
    email: email.value,
    passrecovery: 'true',
    expiraem: '1800'
  }).toString();

  const url =
          "https://gateway.inforserv.pt/cgirdw/qdocloud/client/getemailtoken?" +
          params;

  const _handleKeyDown = function(e) {
            if (e.key === 'Enter') {
              handleRecuperarPassword();
            }
  } 
  
  const _handleSucess = function() {
        props.history.push('/');
  }

  const handleRecuperarPassword = (e) => {
       e.preventDefault();
       let valid = false;
       if (email.value === '') {
         setError('Introduza o Endereço de Email!')
         return;
       }
       valid = true;
    if (valid) {
    setError(null);
    setLoading(true);
    axios.post(url, data).then(response => {
      setLoading(false);
      if (response.data.error === true) {
      setError(response.data.message);
      } else {
       if (response.data.token !== '') {
          setSucess(true)
       }
      }
    }).catch(error => {
      setLoading(false);
      console.log('handleRecuperar: ' + error);
      setError("Algo deu errado. Por favor, tente novamente mais tarde.");
    });
   }
  }

    return (
        <>
        <main>
          <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
            <Container>
              <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                    <div className="text-center text-md-center mb-4 mt-md-0">
                      <h3 className="mb-0">Recuperar a Palavra Passe</h3>
                    </div>
                    <Form className="mt-4">
                      <Form.Group id="email" className="mb-4">
                        <Form.Label>Endereço de Email</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                          <Form.Control autoFocus required type="email" {...email} placeholder="Endereço de Email" onKeyDown={_handleKeyDown} />
                        </InputGroup>
                        <div className="d-flex justify-content-between align-items-center mb-4 mt-4">
                          <Card.Link href="/" className="small text-end" style={{ marginLeft: "auto" }}>Iniciar Sessão</Card.Link>
                        </div>
                      </Form.Group>
                      <Button variant="primary" type="submit" onClick={handleRecuperarPassword} className="w-100" disabled={loading}>{loading ? 'Aguarde...' : 'Recuperar Palavra Passe'}</Button>
                    </Form>

                    {error && <div className="alert alert-danger mt-2" role="alert">{error}</div>}

                    <div style={{width: "100%", height: "103px" }}>&nbsp;</div>
  
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        {
          sucess ? <SweetAlert
                   success
                   title="Recuperar a Palavra Passe"
                   onConfirm={_handleSucess}
                   >
                   Por favor verifique a sua caixa de Email!<br/>
                   E siga as intruções enviadas.
                   </SweetAlert> : null
        }
        </>
      )
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value);
   }
    return {
      value,
      onChange: handleChange
    }
  }

export default RecuperarPassword;