import React, { useState, useEffect } from 'react';
import { Bar, Pie, defaults } from 'react-chartjs-2';
import axios from 'axios';
import { getToken } from '../Components/Common/common';
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import { FurosValueWidget, ArmariosValueWidget, LagosValueWidget } from "../Components/Widgets";

function Dashboard(){
  const [tags, setTags] = useState({});
  const [lasttotais, setLasttotais] = useState({});
  const [totais, setTotais] = useState({});

  useEffect(function() {
    let isMounted = true;
    let listLastTotais = {};
    let listTotais = {};
    let m = new Date().getMonth(); 
    let params = new URLSearchParams({
      mes: m,
      token: getToken()
    }).toString();
  
    let url =
            "https://gateway.inforserv.pt/cgirdw/restserver/client/totaislotes?" +
            params;
      
    if (isMounted) {     
    axios.get(url).then(response => {
         response.data.forEach(function(ltot){
           listLastTotais.lote18 = ltot.lote18;
           listLastTotais.lote35 = ltot.lote35;
           listLastTotais.lote37 = ltot.lote37;
           listLastTotais.lote38 = ltot.lote38;
           listLastTotais.lote39 = ltot.lote39;
           listLastTotais.total = ltot.total;
           listLastTotais.lote18percent = ltot.lote18percent;
           listLastTotais.lote35percent = ltot.lote35percent;
           listLastTotais.lote37percent = ltot.lote37percent;
           listLastTotais.lote38percent = ltot.lote38percent;
           listLastTotais.lote39percent = ltot.lote39percent;
         })

       setLasttotais(listLastTotais); 

    }).catch(console.log);

    m = new Date().getMonth() + 1; 
    params = new URLSearchParams({
      mes: m,
      token: getToken()
    }).toString();
  
     url =
            "https://gateway.inforserv.pt/cgirdw/restserver/client/totaislotes?" +
            params;
    
            axios.get(url).then(response => {
              response.data.forEach(function(tot){
                listTotais.lote18 = tot.lote18;
                listTotais.lote35 = tot.lote35;
                listTotais.lote37 = tot.lote37;
                listTotais.lote38 = tot.lote38;
                listTotais.lote39 = tot.lote39;
                listTotais.total = tot.total;
                listTotais.lote18percent = tot.lote18percent;
                listTotais.lote35percent = tot.lote35percent;
                listTotais.lote37percent = tot.lote37percent;
                listTotais.lote38percent = tot.lote38percent;
                listTotais.lote39percent = tot.lote39percent;
              })
     
            setTotais(listTotais); 
     
         }).catch(console.log);        
   }
   return () => { isMounted = false; };
  }, []);

  useEffect(function() {
    const interval = setInterval(() => {
    let isMounted = true;
    let listTags = {};
    const request = 
    '[' +
    '"QdO.Furo2.PressaoAtual","QdO.Furo2.Timer","QdO.Furo2.Auto","QdO.Furo2.Estado","QdO.Furo2.VelocidadeAtual",' +
    '"QdO.Furo3.PressaoAtual","QdO.Furo3.Timer","QdO.Furo3.Auto","QdO.Furo3.Estado","QdO.Furo3.VelocidadeAtual",' +
    '"QdO.A4.PressaoAtual","QdO.A4.Faulted","QdO.A5.PressaoAtual","QdO.A5.Faulted",' +
    '"QdO.A4.LT18Auto","QdO.A4.LT18Ligar","QdO.A4.LT18Estado","QdO.A4.LT18CaudalAtual","QdO.A4.LT18Acumulado1","QdO.A4.LT18Acumulado2",' +
    '"QdO.A4.L21Auto","QdO.A4.L21Ligar","QdO.A4.L21Estado","QdO.A4.L21CaudalAtual","QdO.A4.L21Acumulado1","QdO.A4.L21Acumulado2",' +
    '"QdO.A5.LT35Auto","QdO.A5.LT35Ligar","QdO.A5.LT35Estado","QdO.A5.LT35CaudalAtual","QdO.A5.LT35Acumulado1","QdO.A5.LT35Acumulado2",' +
    '"QdO.A5.LT37Auto","QdO.A5.LT37Ligar","QdO.A5.LT37Estado","QdO.A5.LT37CaudalAtual","QdO.A5.LT37Acumulado1","QdO.A5.LT37Acumulado2",' +
    '"QdO.A5.LT38Auto","QdO.A5.LT38Ligar","QdO.A5.LT38Estado","QdO.A5.LT38CaudalAtual","QdO.A5.LT38Acumulado1","QdO.A5.LT38Acumulado2",' +
    '"QdO.A5.LT39Auto","QdO.A5.LT39Ligar","QdO.A5.LT39Estado","QdO.A5.LT39CaudalAtual","QdO.A5.LT39Acumulado1","QdO.A5.LT39Acumulado2",' +
    '"QdO.A5.L6Auto","QdO.A5.L6Ligar","QdO.A5.L6Estado","QdO.A5.L6CaudalAtual","QdO.A5.L6Acumulado1","QdO.A5.L6Acumulado2"' +
    ']';

  if (isMounted) {
  axios.post('https://iotgateway.inforserv.pt:39321/iotgateway/read', 
  request,
  {
      headers: {
        "Content-Type": "application/json"
  }
}).then(async function(resultado){
  await resultado.data.readResults.forEach(function(tag){
       switch(tag.id) {
         case 'QdO.Furo2.PressaoAtual':
          listTags.f2pressaoatual = (tag.v / 10)
          break;
         case 'QdO.Furo2.Timer':
          listTags.f2timer = tag.v
          break;
         case 'QdO.Furo2.Auto':
            listTags.f2auto = tag.v
            break; 
         case 'QdO.Furo2.Estado':
            listTags.f2status = tag.v
            break;   
         case 'QdO.Furo2.VelocidadeAtual':  
          listTags.f2velocidadeatual = (tag.v / 10)
          break;
         case 'QdO.Furo3.PressaoAtual':
          listTags.f3pressaoatual = (tag.v / 10)
          break;
         case 'QdO.Furo3.Timer':
          listTags.f3timer = tag.v
          break;
         case 'QdO.Furo3.Auto':
          listTags.f3auto = tag.v
          break; 
         case 'QdO.Furo3.Estado':
            listTags.f3status = tag.v
            break;    
         case 'QdO.Furo3.VelocidadeAtual':
            listTags.f3velocidadeatual = (tag.v / 10)
            break; 
         case 'QdO.A4.PressaoAtual':
            listTags.a4pressaoatual = (tag.v / 10)
            break; 
         case 'QdO.A4.Estado':
            listTags.a4status = tag.v
            break; 
         case 'QdO.A5.PressaoAtual':
            listTags.a5pressaoatual = (tag.v / 10)
            break; 
         case 'QdO.A5.Estado':
            listTags.a5status = tag.v
            break;
         // LOTE 18
         case 'QdO.A4.LT18Auto':
              listTags.lt18auto = tag.v
            break;
         case 'QdO.A4.LT18Ligar':
              listTags.lt18ligar = tag.v
            break; 
         case 'QdO.A4.LT18Estado':
              listTags.lt18estado = tag.v
            break;
         case 'QdO.A4.LT18CaudalAtual':
              listTags.lt18caudalatual = (tag.v / 10)
            break;
         case 'QdO.A4.LT18Acumulado1':
              listTags.lt18acumulado1 = (tag.v / 10)
            break; 
         case 'QdO.A4.LT18Acumulado2':
              listTags.lt18acumulado2 = (tag.v / 10)
            break;
         // LAGO 21
         case 'QdO.A4.L21Auto':
              listTags.l21auto = tag.v
            break;
         case 'QdO.A4.L21Ligar':
              listTags.l21ligar = tag.v
            break; 
         case 'QdO.A4.L21Estado':
              listTags.l21estado = tag.v
            break;
         case 'QdO.A4.L21CaudalAtual':
              listTags.l21caudalatual = (tag.v / 10)
            break;
         case 'QdO.A4.L21Acumulado1':
              listTags.l21acumulado1 = (tag.v / 10)
            break; 
         case 'QdO.A4.L21Acumulado2':
              listTags.l21acumulado2 = (tag.v / 10)
            break;
         // LOTE 35
         case 'QdO.A5.LT35Auto':
              listTags.lt35auto = tag.v
            break;
         case 'QdO.A5.LT35Ligar':
              listTags.lt35ligar = tag.v
            break; 
         case 'QdO.A5.LT35Estado':
              listTags.lt35estado = tag.v
            break;
         case 'QdO.A5.LT35CaudalAtual':
              listTags.lt35caudalatual = (tag.v / 10)
            break;
         case 'QdO.A5.LT35Acumulado1':
              listTags.lt35acumulado1 = (tag.v / 10)
            break; 
         case 'QdO.A5.LT35Acumulado2':
              listTags.lt35acumulado2 = (tag.v / 10)
            break; 
         // LOTE 37
         case 'QdO.A5.LT37Auto':
              listTags.lt37auto = tag.v
            break;
         case 'QdO.A5.LT37Ligar':
              listTags.lt37ligar = tag.v
            break; 
         case 'QdO.A5.LT37Estado':
              listTags.lt37estado = tag.v
            break;
         case 'QdO.A5.LT37CaudalAtual':
              listTags.lt37caudalatual = (tag.v / 10)
            break;
         case 'QdO.A5.LT37Acumulado1':
              listTags.lt37acumulado1 = (tag.v / 10)
            break; 
         case 'QdO.A5.LT37Acumulado2':
              listTags.lt37acumulado2 = (tag.v / 10)
            break;
         // LOTE 38
         case 'QdO.A5.LT38Auto':
              listTags.lt38auto = tag.v
            break;
         case 'QdO.A5.LT38Ligar':
              listTags.lt38ligar = tag.v
            break; 
         case 'QdO.A5.LT38Estado':
              listTags.lt38estado = tag.v
            break;
         case 'QdO.A5.LT38CaudalAtual':
              listTags.lt38caudalatual = (tag.v / 10)
            break;
         case 'QdO.A5.LT38Acumulado1':
              listTags.lt38acumulado1 = (tag.v / 10)
            break; 
         case 'QdO.A5.LT38Acumulado2':
              listTags.lt38acumulado2 = (tag.v / 10)
            break;
         // LOTE 39
         case 'QdO.A5.LT39Auto':
              listTags.lt39auto = tag.v
            break;
         case 'QdO.A5.LT39Ligar':
              listTags.lt39ligar = tag.v
            break; 
         case 'QdO.A5.LT39Estado':
              listTags.lt39estado = tag.v
            break;
         case 'QdO.A5.LT39CaudalAtual':
              listTags.lt39caudalatual = (tag.v / 10)
            break;
         case 'QdO.A5.LT39Acumulado1':
              listTags.lt39acumulado1 = (tag.v / 10)
            break; 
         case 'QdO.A5.LT39Acumulado2':
              listTags.lt39acumulado2 = (tag.v / 10)
            break;
         // LAGO 6
         case 'QdO.A5.L6Auto':
              listTags.l6auto = tag.v
            break;
         case 'QdO.A5.L6Ligar':
              listTags.l6ligar = tag.v
            break; 
         case 'QdO.A5.L6Estado':
              listTags.l6estado = tag.v
            break;
         case 'QdO.A5.L6CaudalAtual':
              listTags.l6caudalatual = (tag.v / 10)
            break;
         case 'QdO.A5.L6Acumulado1':
              listTags.l6acumulado1 = (tag.v / 10)
            break; 
         case 'QdO.A5.L6Acumulado2':
              listTags.l6acumulado2 = (tag.v / 10)
            break;                                                       
         default:  
       }
  })

  setTags(listTags);

}).catch(console.log);
  }
  }, 1000);
  return () => {
  setTags({});  
  clearInterval(interval);
  }
  }, []);

  const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
  "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
  const d = new Date().getMonth();
  const s = 'Água de Rega para Lotes (%) ';
  const m = 'Água de Rega para Lotes (m3) ';

  const percentdata = {
    labels: ['Lote 18', 'Lote 35', 'Lote 37', 'Lote 38', 'Lote 39'],
    datasets: [
      {
        label: 'Percentagem',
        data: [
          totais.lote18percent, 
          totais.lote35percent, 
          totais.lote37percent, 
          totais.lote38percent,
          totais.lote39percent],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataM = {
    labels: ['Lote 18', 'Lote 35', 'Lote 37', 'Lote 38', 'Lote 39'],
    datasets: [
      {
        label: monthNames[d - 1],
        data: [
          lasttotais.lote18, 
          lasttotais.lote35, 
          lasttotais.lote37, 
          lasttotais.lote38,
          lasttotais.lote39],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
      {
        label: monthNames[d],
        data: [
          totais.lote18, 
          totais.lote35, 
          totais.lote37, 
          totais.lote38,
          totais.lote39],
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
          'rgba(153, 102, 255, 0.5)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const option = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        backgroundColor: '#fd7b33',
        borderColor: '#ffffff',
        borderWidth: 1,
        titleColor: '#ffffff',
        titleAlign: 'center',
        displayColors: true,
        bodyAlign: 'right',
        padding: 10,
        usePointStyle: true,
        callbacks: {
          title: function(tooltipItem) {
            return tooltipItem[0].label;
          },
          labelTextColor: function(context) {
            return '#262B40';
          },
          label: function(context) {
            var label = context.dataset.label || '';

            if (label) {
                label += ': ';
            }
            if (context.parsed.y !== null) {
                label += context.parsed + '%';
            }
            return label;
          },
          labelPointStyle: function(context) {
            return {
                pointStyle: 'rectRounded',
                rotation: 0
            };
         }
        }
      },
    }
  };

  const option2 = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        backgroundColor: '#fd7b33',
        borderColor: '#ffffff',
        borderWidth: 1,
        titleColor: '#ffffff',
        titleAlign: 'center',
        displayColors: true,
        bodyAlign: 'right',
        padding: 10,
        usePointStyle: true,
        callbacks: {
          labelTextColor: function(context) {
            return '#262B40';
          },
          label: function(context) {
            var label = context.dataset.label || '';

            if (label) {
                label += ': ';
            }
            if (context.parsed.y !== null) {
                label += context.parsed.y + ' m3';
            }
            return label;
          },
          labelPointStyle: function(context) {
            return {
                pointStyle: 'rectRounded',
                rotation: 0
            };
         },
        }
      },
    }
  };

  defaults.animation = false;
  
    return (
    <>  
    <div style={{marginRight: 'auto', marginLeft: 'auto', maxWidth: '95%', paddingTop: '100px'}}>
       <Row className="justify-content-md-center">
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <FurosValueWidget
            title="Furo 2"
            value={tags.f2pressaoatual}
            velocity={tags.f2velocidadeatual}
            timer={tags.f2timer}
            auto={tags.f2auto}
            status={tags.f2status}
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <FurosValueWidget
            title="Furo 3"
            value={tags.f3pressaoatual}
            velocity={tags.f3velocidadeatual}
            timer={tags.f3timer}
            auto={tags.f3auto}
            status={tags.f3status}
          />
        </Col>
        </Row>
        <Row className="justify-content-md-center">
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <ArmariosValueWidget
            title="Armário 4"
            value={tags.a4pressaoatual}
            status={tags.a4status}
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
        <ArmariosValueWidget
            title="Armário 5"
            value={tags.a5pressaoatual}
            status={tags.a5status}
          />
        </Col>
         </Row>
         <Row className="justify-content-md-center">
         <Col xs={12} sm={6} xl={4} className="mb-4">
         <Card className="bg-secondary-alt shadow-sm">
         <Card.Header className="d-flex flex-row align-items-center flex-0">
         <div className="d-block">
          <h5 className="fw-normal mb-2">
            {s + monthNames[d]}
          </h5>
          </div>
          </Card.Header>
          <Card.Body className="d-flex flex-row align-items-center flex-0">
         <Pie 
         data={percentdata}
         width={250}
	       height={150} 
         options={option}
         />
         </Card.Body>
         </Card>
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
        <Card className="bg-secondary-alt shadow-sm">
         <Card.Header className="d-flex flex-row align-items-center flex-0">
         <div className="d-block">
          <h5 className="fw-normal mb-2">
            {m + monthNames[d - 1] + '/' + monthNames[d]}
          </h5>
          </div>
          </Card.Header>
          <Card.Body className="d-flex flex-row align-items-center flex-0">
         <Bar 
         data={dataM}
         width={250}
	       height={150} 
         options={option2}
         />
         </Card.Body>
         </Card>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <LagosValueWidget
            title="Lago 6"
            auto={tags.l6auto}
            ligar={tags.l6ligar}
            status={tags.l6estado}
            atual={tags.l6caudalatual}
            diario={tags.l6acumulado1}
            mensal={tags.l6acumulado2}
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <LagosValueWidget
            title="Lago 21"
            auto={tags.l21auto}
            ligar={tags.l21ligar}
            status={tags.l21estado}
            atual={tags.l21caudalatual}
            diario={tags.l21acumulado1}
            mensal={tags.l21acumulado2}
          />
        </Col>
        </Row>
        <Row className="justify-content-md-center">
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <LagosValueWidget
            title="Lote 18"
            auto={tags.lt18auto}
            ligar={tags.lt18ligar}
            status={tags.lt18estado}
            atual={tags.lt18caudalatual}
            diario={tags.lt18acumulado1}
            mensal={tags.lt18acumulado2}
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <LagosValueWidget
            title="Lote 35"
            auto={tags.lt35auto}
            ligar={tags.lt35ligar}
            status={tags.lt35estado}
            atual={tags.lt35caudalatual}
            diario={tags.lt35acumulado1}
            mensal={tags.lt35acumulado2}
          />
        </Col>
        </Row>
        <Row className="justify-content-md-center">
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <LagosValueWidget
            title="Lote 37"
            auto={tags.lt37auto}
            ligar={tags.lt37ligar}
            status={tags.lt37estado}
            atual={tags.lt37caudalatual}
            diario={tags.lt37acumulado1}
            mensal={tags.lt37acumulado2}
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <LagosValueWidget
            title="Lote 38"
            auto={tags.lt38auto}
            ligar={tags.lt38ligar}
            status={tags.lt38estado}
            atual={tags.lt38caudalatual}
            diario={tags.lt38acumulado1}
            mensal={tags.lt38acumulado2}
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <LagosValueWidget
            title="Lote 39"
            auto={tags.lt39auto}
            ligar={tags.lt39ligar}
            status={tags.lt39estado}
            atual={tags.lt39caudalatual}
            diario={tags.lt39acumulado1}
            mensal={tags.lt39acumulado2}
          />
        </Col>
        </Row>
    </div>
    </>
    )
  }

export default Dashboard;