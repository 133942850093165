import React, { useState } from "react";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { setUserSession } from '../Components/Common/common';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import './login.css';

import BgImage from "../assets/img/login.svg";

function Login(props){
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const email = useFormInput(cookies.email ? cookies.email : '');
  const password = useFormInput(cookies.password ? cookies.password : '');
  const [savedata, setSavedata] = useState(cookies.rememberme ? cookies.rememberme : false);
  const [error, setError] = useState(null);
  var data = {};
  var params = new URLSearchParams({
    email: email.value,
    password: password.value,
    userdata: true
  }).toString();

  const url =
          "https://gateway.inforserv.pt/cgirdw/restserver/client/gettoken?" +
          params;

  const _handleKeyDown = function(e) {
            if (e.key === 'Enter') {
              handleLogin();
            }
  }  
  
  const toggleCheckboxChange = () => {
       if (savedata) {
         setSavedata(false);
         removeCookie('userid', { path: '/' });
         removeCookie('name', { path: '/' });
         removeCookie('email', { path: '/' });
         removeCookie('password', { path: '/' });
         removeCookie('rememberme', { path: '/' });
       } else {
         setSavedata(true);
       }
  }

  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    axios.post(url, data).then(response => {
      setLoading(false);
      if (response.data.error === true) {
      setError(response.data.message);
      } else {
      var u = JSON.stringify(response.data.user);
      u = JSON.parse(u);
      setUserSession(u.userId, true, response.data.token, response.data.user);
      if (savedata) {
        var date = new Date();
        date.setTime(date.getTime() + (30*24*60*60*1000));
        setCookie('userid', u.userId, { path: '/', expires: date });
        setCookie('name', u.name, { path: '/', expires: date });
        setCookie('email', email.value, { path: '/', expires: date });
        setCookie('password', password.value, { path: '/', expires: date });
        setCookie('rememberme', savedata, { path: '/', expires: date});
      } else {
        removeCookie('userid', { path: '/' });
        removeCookie('name', { path: '/' });
        removeCookie('email', { path: '/' });
        removeCookie('password', { path: '/' });
        removeCookie('rememberme', { path: '/' });
      }
      props.history.push('/home');
      }
    }).catch(error => {
      setLoading(false);
      console.log('handleLogin: ' + error);
      setError("Algo deu errado. Por favor, tente novamente mais tarde.");
    });
  }

    return (
        <main>
          <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
            <Container>
              <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                    <div className="text-center text-md-center mb-4 mt-md-0">
                      <h3 className="mb-0">Autenticação</h3>
                    </div>
                    <Form className="mt-4">
                      <Form.Group id="email" className="mb-4">
                        <Form.Label>Endereço de Email</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                          <Form.Control autoFocus required type="email" {...email} placeholder="Endereço de Email" />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group>
                        <Form.Group id="password" className="mb-4">
                          <Form.Label>Palavra Passe</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>
                            <Form.Control required type="password" {...password} placeholder="Palavra Passe" onKeyDown={_handleKeyDown} />
                          </InputGroup>
                        </Form.Group>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <Form.Check id="defaultCheck1" type="checkbox">
                            <FormCheck.Input id="defaultCheck1" className="me-2" onChange={toggleCheckboxChange} defaultChecked={savedata} />
                            <FormCheck.Label htmlFor="defaultCheck1" className="mb-0">Lembrar-me</FormCheck.Label>
                          </Form.Check>
                          <Card.Link href="/recpassword" className="small text-end">Esqueceu a Palavra Passe?</Card.Link>
                        </div>
                      </Form.Group> 
                      <Button variant="primary" type="submit" onClick={handleLogin} className="w-100" disabled={loading}>{loading ? 'Aguarde...' : 'Iniciar Sessão'}</Button>
                    </Form>

                    {error && <div className="alert alert-danger mt-2" role="alert">{error}</div>}
  
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      )
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value);
   }
    return {
      value,
      onChange: handleChange
    }
  }

export default Login;